import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { graphql } from "gatsby"
import Card from "../components/Card/card"
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"


export const pageQuery = graphql`
  query CuerpasQueryLocale($locale: String) {
    itemCuerpas: allContentfulWorks(
      filter: {
        AlbumCategory: { slug: { eq: "cuerpas" }, node_locale: { eq: $locale } }
        destacar: { eq: "si" }
      }
      limit: 2
    ) {
      edges {
        node {
          id
          title
          AlbumCategory {
            title
            slug
          }
          slug
          featuredImg {
            fixed(width: 300, height: 600) {
              ...GatsbyContentfulFixed
            }
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      totalCount
    }
    itemBandas: allContentfulWorks(
      filter: {
        AlbumCategory: { slug: { eq: "bandas" }, node_locale: { eq: $locale } }
        destacar: { eq: "si" }
      }
      limit: 2
      sort: {fields: updatedAt, order: DESC}
    ) {
      edges {
        node {
          id
          title
          AlbumCategory {
            title
            slug
          }
          slug
          featuredImg {
            fixed(width: 300, height: 600) {
              ...GatsbyContentfulFixed
            }
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      totalCount
    }
    itemAutoretratos: allContentfulWorks(
      filter: {
        AlbumCategory: { slug: { eq: "autorretratos" }, node_locale: { eq: $locale } }
        destacar: { eq: "si" }
      }
      limit: 2
    ) {
      edges {
        node {
          id
          title
          AlbumCategory {
            title
            slug
          }
          slug
          featuredImg {
            fixed(width: 300, height: 600) {
              ...GatsbyContentfulFixed
            }
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      totalCount
    }
    itemMarcas: allContentfulWorks(
      filter: {
        AlbumCategory: { slug: { eq: "marcas" }, node_locale: { eq: $locale } }
        destacar: { eq: "si" }
      }
      limit: 2
    ) {
      edges {
        node {
          id
          title
          AlbumCategory {
            title
            slug
          }
          slug
          featuredImg {
            fixed(width: 300, height: 600) {
              ...GatsbyContentfulFixed
            }
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
      totalCount
    }
  }
`

const AlbumsPage = ({ data }) => {
  const intl = useIntl()

  return (
    <Layout>
      <SEO lang={intl.locale} title="Albums" />
      <div className="max-w-4xl px-2 pt-32 m-auto ">
        <div className="flex flex-col text-center">
          <Title>
            <h2 tw="flex-1 w-full text-3xl text-left text-white duration-500 ">
              <FormattedMessage id="cat_bodies" />
            </h2>
            <Link
              tw="inline-block ml-3 text-base tracking-wider text-yellow-700 uppercase duration-500 border-b border-yellow-700 md:ml-3"
              to={`/albums/cuerpas`}
            >
               <FormattedMessage id="see_albums" />
            </Link>
          </Title>
          <Grid>
            {data.itemCuerpas.edges.map(({ node }) => {
              return (
                <Item key={node.slug}>
                  <Card
                    heading={node.title}
                    text={node.title}
                    album={node.AlbumCategory.slug}
                    slug={node.slug}
                    image={node.featuredImg.fluid}
                  />
                </Item>
              )
            })}
          </Grid>
          {/*<Categories />*/}
        </div>
        <div className="flex flex-col text-center">
          <Title>
            <h2 tw="flex-1 w-full text-3xl text-left text-white duration-500 ">
              <FormattedMessage id="cat_bands" />
            </h2>
            <Link
              tw="inline-block ml-3 text-base tracking-wider text-yellow-700 uppercase duration-500 border-b border-yellow-700 md:ml-3"
              to={`/albums/bandas`}
            >
              <FormattedMessage id="see_albums" />
            </Link>
          </Title>
          <Grid>
            {data.itemBandas.edges.map(({ node }) => {
              return (
                <Item key={node.slug}>
                  <Card
                    heading={node.title}
                    text={node.title}
                    album={node.AlbumCategory.slug}
                    slug={node.slug}
                    image={node.featuredImg.fluid}
                  />
                </Item>
              )
            })}
          </Grid>
          {/*<Categories />*/}
        </div>
        <div className="flex flex-col text-center">
          <Title>
            <h2 tw="flex-1 w-full text-3xl text-left text-white duration-500 ">
              <FormattedMessage id="cat_brands" />
            </h2>
            <Link
              tw="inline-block ml-3 text-base tracking-wider text-yellow-700 uppercase duration-500 border-b border-yellow-700 md:ml-3"
              to={`/albums/marcas`}
            >
               <FormattedMessage id="see_albums" />
            </Link>
          </Title>
          <Grid>
            {data.itemMarcas.edges.map(({ node }) => {
              return (
                <Item key={node.slug}>
                  <Card
                    heading={node.title}
                    text={node.title}
                    album={node.AlbumCategory.slug}
                    slug={node.slug}
                    image={node.featuredImg.fluid}
                  />
                </Item>
              )
            })}
          </Grid>
          {/*<Categories />*/}
        </div>
        <div className="flex flex-col text-center">
          <Title>
            <h2 tw="flex-1 w-full text-3xl text-left text-white duration-500 ">
              <FormattedMessage id="cat_self" />
            </h2>
            <Link
              tw="inline-block ml-3 text-base tracking-wider text-yellow-700 uppercase duration-500 border-b border-yellow-700 md:ml-3"
              to={`/albums/autorretratos`}
            >
               <FormattedMessage id="see_albums" />
            </Link>
            
          </Title>
          <Grid>
            {data.itemAutoretratos.edges.map(({ node }) => {
              return (
                <Item key={node.slug}>
                  <Card
                    heading={node.title}
                    text={node.title}
                    album={node.AlbumCategory.slug}
                    slug={node.slug}
                    image={node.featuredImg.fluid}
                  />
                </Item>
              )
            })}
          </Grid>
          {/*<Categories />*/}
        </div>
      </div>
    </Layout>
  )
}

export default AlbumsPage

const Title = styled.div`
  ${tw`flex items-baseline w-full`}
`

const Grid = styled.div`
  ${tw`relative grid w-full grid-cols-2 gap-1 mt-3 mb-12`}
`

const Item = styled.div`
  ${tw`relative `}
  transition: all 1s;

  &:hover {
    opacity: 1;

    a {
      opacity: 1;
    }
  }
`
